// @ts-nocheck
/* eslint-env browser */
/* global PARTYKIT_HOST */

import "./styles.css";

import PartySocket from "partysocket";

/**
 * A PartySocket is like a WebSocket, but with more features.
 * It handles reconnection logic, buffering messages while it's offline, etc.
 * @type {PartySocket} - The connection object
 */
conn = new PartySocket({
  // @ts-expect-error This should be typed as a global string
  host: PARTYKIT_HOST,
  room: "my-new-room",
});

/**
 * Event listener to handle received messages.
 * @param {Event} event - The message event
 */
conn.addEventListener("message", function (event) {
  
  let message = {};
  try {
    message = JSON.parse(event.data); // Assuming structured approach
  } catch (error) {

  }
//
  if (message && message.type === 'cssSelector') {
    applyStyleBySelector(message.selector);
    createSpeechBubble(message.selector, 'Other started:');
    scrollAndPulsate(message.selector);
  } else if (message && message.type === 'cssSelectorAppendMsg') {
    applyStyleBySelector(message.selector);
    appendMsgToSpeechBubble(message.selector, message.message);
    scrollAndPulsate(message.selector);
  } else {
    console.log("Other=", event.data);
    // Handle other message types (e.g., chat messages, game updates)
    // ...
  }
});

// @ts-nocheck
document.addEventListener('mouseup', function () {
  const selection = window.getSelection();
  if (selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      let selectedNode = range.commonAncestorContainer;

      // Find the closest stylable parent
      while (selectedNode && !selectedNode.style) {
          selectedNode = selectedNode.parentNode;
      }
      console.log(selectedNode)


      if (selectedNode) {
          const cssSelector = getCssSelector(selectedNode);
          if(document.getElementById(cssSelector)) {
            return;
          }
          applyStyleBySelector(cssSelector);
          createSpeechBubble(cssSelector, 'Me started:');
          const messageData = {
              type: 'cssSelector',
              selector: cssSelector
          };
          conn.send(JSON.stringify(messageData));
      }
  }
});

function applyStyleBySelector(selector) {
  const selectedNode = document.querySelector(selector);
  if (selectedNode) {
      selectedNode.style.backgroundColor = 'yellow';
      selectedNode.style.fontWeight = 'bold';
  } else {
      console.warn(`No element found for selector: ${selector}`);
  }
}

function getCssSelector(selectedNode) {

  if (!selectedNode) {
      return null; // Handle case where no stylable parent is found
  }

  let selector = '';
  let currentNode = selectedNode;

  while (currentNode) {
      if (currentNode.nodeType === Node.ELEMENT_NODE) {
          const tagName = currentNode.tagName.toLowerCase();
          const index = Array.prototype.indexOf.call(currentNode.parentNode.children, currentNode) + 1;
          selector = `${tagName}:nth-child(${index}) ${selector}`;
      }
      currentNode = currentNode.parentNode;
  }

  return selector.trim();
}

function createSpeechBubble(selector, message) {
  const bubble = document.createElement('div');
  bubble.addEventListener('mouseup', (event) => {
      event.stopPropagation();
  }, true);
  bubble.classList.add('speech-bubble');
  bubble.id = selector;

  const messageElement = document.createElement('div');
  messageElement.textContent = message;
  const containerMessageElement = document.createElement('div');
  containerMessageElement.appendChild(messageElement);
  bubble.appendChild(containerMessageElement);
  bubble.appendChild(createTextInputWithButton(selector));

  // Position the bubble above the selected element
  const selectedElement = document.querySelector(selector);
  const rect = selectedElement.getBoundingClientRect();
  bubble.style.top = `${rect.top - 30}px`;
  bubble.style.left = `${rect.left}px`;

  bubble.style.position = 'absolute';
  bubble.style.backgroundColor = '#f0f0f0';
  bubble.style.border = '1px solid #ccc';
  bubble.style.padding = '6px';
  bubble.style.borderRadius = '6px';
  bubble.style.opacity = 0.7;

  bubble.style.maxHeight = '30px';
  bubble.style.maxWidth = '60px';
  bubble.style.overflow = 'hidden';


  bubble.addEventListener('mouseover', function () {
      this.style.maxHeight = 'none';
      this.style.maxWidth = 'none';
      this.style.opacity = 0.95;
  });

  bubble.addEventListener('mouseout', function () {
      this.style.opacity = 0.7;
      this.style.maxHeight = '30px';
      this.style.maxWidth = '60px';
  });

  // Append the bubble to the body
  document.body.appendChild(bubble);
}

function appendMsgToSpeechBubble(selector, message) {
  const myDiv = document.getElementById(selector);
  const newDiv = document.createElement("div");
  newDiv.textContent = message;
  myDiv.firstChild.appendChild(newDiv);

}


function createTextInputWithButton(cssSelector) {
  const input = document.createElement('input');
  input.type = 'text';
  input.placeholder = 'Enter text here';

  const button = document.createElement('button');
  button.textContent = 'Send';

  const onSendMsg = () => {
      const messageData = {
          type: 'cssSelectorAppendMsg',
          selector: cssSelector,
          message: input.value,
      };
      appendMsgToSpeechBubble(cssSelector, input.value)
      conn.send(JSON.stringify(messageData));
      input.value = '';
  }

  button.addEventListener('click', onSendMsg);
  input.addEventListener('keydown', function (event) {
      if (event.key === 'Enter') {
          onSendMsg();
      }
  });

  // Create a container element to hold the input and button
  const container = document.createElement('div');
  container.appendChild(input);
  container.appendChild(button);

  return container;
}


function scrollAndPulsate(selector) {
  const element = document.getElementById(selector);
  if (element) {
      element.scrollTop = element.scrollHeight;
      element.classList.add('pulsate');
      element.style.maxHeight = 'none';
      element.style.maxWidth = 'none';
      element.style.opacity = 0.95;
      setTimeout(() => {
          element.classList.remove('pulsate');
          element.style.opacity = 0.7;
          element.style.maxHeight = '30px';
          element.style.maxWidth = '60px';
          element.scrollTop = element.scrollHeight;
      }, 3000);
  } else {
      console.warn(`Element with selector "${selector}" not found.`);
  }
}

